@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hotkey-box {
  border-radius: 10px;
  padding: 25px;
}

.hotkey-icon {
  vertical-align: bottom;
  padding-right: 7px;
  height: 35px;
  width: 35px;
}

.welcome-page {
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  animation: fadeIn ease 3s;
}

.welcome-logo {
  background-color: #21ce99;
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 7px;
  position: relative;
}

/* Styles the <pre> tag to extract linebreaks from excel */
pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  overflow-y: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 0;
  font-size: 20px;
  line-height: 40px;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}
